<template>
    <b-container fluid>
        <b-row>
            <b-col class="pl-0">
                <b-button variant="link" @click="save()">Сохранить</b-button>
                <b-button variant="link" @click="$router.go(-1)">Закрыть</b-button>
            </b-col>
            <b-col class="text-right">
                <b-button variant="primary" @click="commit()">Подтвердить в DLV</b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col xl="3" md="4" cols="12">
                <b-form-group label="DLV Login">
                    <b-form-input disabled v-model="entity.dlvLogin"/>
                </b-form-group>
            </b-col>
            <b-col xl="3" md="4" cols="12">
                <b-form-group label="DLV Id">
                    <b-form-input disabled v-model="entity.dlvId"/>
                </b-form-group>
            </b-col>
            <b-col xl="3" md="4" cols="12">
                <b-form-group label="Номер">
                    <b-form-input disabled v-model="entity.num"/>
                </b-form-group>
            </b-col>
            <b-col xl="3" md="4" cols="12">
                <b-form-group label="Курерский номер">
                    <b-form-input disabled v-model="entity.courierNum"/>
                </b-form-group>
            </b-col>
            <b-col xl="3" md="4" cols="12">
                <b-form-group label="Статус">
                    <b-form-input disabled v-model="entity.status"/>
                </b-form-group>
            </b-col>
            <b-col xl="3" md="4" cols="12">
                <b-form-group label="Курерская служба">
                    <b-form-input disabled v-model="entity.courierCode"/>
                </b-form-group>
            </b-col>
            <b-col xl="3" md="4" cols="12">
                <b-form-group label="Продукт">
                    <b-form-input disabled v-model="entity.courierProduct"/>
                </b-form-group>
            </b-col>
            <b-col xl="3" md="4" cols="12">
                <b-form-group label="Дата сбора">
                    <b-form-input disabled v-model="entity.pickUpDate"/>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row v-if="entity.error">
            <b-col cols="12">
                <b-form-group label="Ошибка">
                    <b-form-input disabled v-model="entity.error"/>
                </b-form-group>
            </b-col>
        </b-row>
        <b-table :items="places" :fields="tableFields"></b-table>
    </b-container>
</template>

<script>
    export default {
        name: "ShipmentEditor",
        created() {
            if (this.$route.params.id) {
                this.getEntity(this.$route.params.id);
                this.getPlaces(this.$route.params.id);
            }
        },
        data() {
            return {
                entity: {
                    id: null,
                    dlvLogin: null,
                    dlvId: null,
                    num: null,
                    courierNum: null,
                    status: null,
                    courierCode: null,
                    courierProduct: null,
                    completed: null,
                    error: null,
                },
                places: [],
                tableFields: [
                    {
                        key: 'barcode',
                        label: 'ШК',
                    }, {
                        key: 'dlvPlaceId',
                        label: 'Идентификатор места в DLV',
                    }, {
                        key: 'accepted',
                        label: 'Принято',
                    }, {
                        key: 'dimension',
                        label: 'Размеры',
                        formatter: value => "(" + value.width + "x" + value.height + "x" + value.depth + ") " + value.weight + "г."
                    }, {
                        key: 'actions',
                        label: 'Действия'
                    }
                ],
            }
        },
        methods: {
            save() {
                this.$http.post(this.$root.apiUrl + '/api/shipment', this.entity, {headers: this.$root.getHeaders()})
                    .then(response => {
                        if (response.body.success) {
                            this.$router.push("/users");
                        } else {
                            alert(response.body.errors.map(error => error.message).reduce((acc, value) => acc + '<br>' + value));
                        }
                    }, response => {
                        this.$root.handleErrorResponse(response);
                    });
            },
            getEntity(id) {
                this.$http.get(this.$root.apiUrl + '/api/shipment/' + id, {headers: this.$root.getHeaders()})
                    .then(response => {
                            if (response.body.success) {
                                this.entity = response.body.entity;
                                this.getHistory(this.entity.syncRecord.id);
                            } else {
                                alert(response.body.errors.map(error => error.message).reduce((acc, value) => acc + '<br>' + value));
                            }
                        },
                        response => {
                            this.$root.handleErrorResponse(response);
                        }
                    );
            },
            getPlaces(id) {
                this.$http.get(this.$root.apiUrl + '/api/places/' + id, {headers: this.$root.getHeaders()})
                    .then(response => {
                            if (response.body.success) {
                                this.places = response.body.list;
                            } else {
                                alert(response.body.errors.map(error => error.message).reduce((acc, value) => acc + '<br>' + value));
                            }
                        },
                        response => {
                            this.$root.handleErrorResponse(response);
                        }
                    );
            },
            commit() {
                this.$http.post(this.$root.apiUrl + '/api/commit-shipment', {id: this.entity.id}, {headers: this.$root.getHeaders()})
                    .then(response => {
                        if (response.body.success) {
                            alert("Успешно");
                        } else {
                            alert(response.body.errors.map(error => error.message).reduce((acc, value) => acc + '<br>' + value));
                        }
                    }, response => {
                        this.$root.handleErrorResponse(response);
                    });
            },
        }
    }
</script>

<style scoped>
</style>